	/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/06/2016 10:36
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("/assets/custom/flaticons/mmpsicologia_font/Flaticon.eot");
  src: url("/assets/custom/flaticons/mmpsicologia_font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("/assets/custom/flaticons/mmpsicologia_font/Flaticon.woff") format("woff"),
       url("/assets/custom/flaticons/mmpsicologia_font/Flaticon.ttf") format("truetype"),
       url("/assets/custom/flaticons/mmpsicologia_font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/assets/custom/flaticons/mmpsicologia_font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-agenda:before { content: "\f100"; }
.flaticon-business:before { content: "\f101"; }
.flaticon-business-1:before { content: "\f102"; }
.flaticon-commerce:before { content: "\f103"; }
.flaticon-couple:before { content: "\f104"; }
.flaticon-education:before { content: "\f105"; }
.flaticon-family-of-four-with-two-minors-and-two-adults:before { content: "\f106"; }
.flaticon-favorite:before { content: "\f107"; }
.flaticon-interface:before { content: "\f108"; }
.flaticon-interface-1:before { content: "\f109"; }
.flaticon-interface-2:before { content: "\f10a"; }
.flaticon-kids-couple:before { content: "\f10b"; }
.flaticon-map:before { content: "\f10c"; }
.flaticon-medical:before { content: "\f10d"; }
.flaticon-note:before { content: "\f10e"; }
.flaticon-note-1:before { content: "\f10f"; }
.flaticon-old-man-walking-with-a-crutch:before { content: "\f110"; }
.flaticon-people:before { content: "\f111"; }
.flaticon-people-1:before { content: "\f112"; }
.flaticon-people-2:before { content: "\f113"; }
.flaticon-shapes:before { content: "\f114"; }
.flaticon-square:before { content: "\f115"; }
.flaticon-symbol:before { content: "\f116"; }
.flaticon-tool:before { content: "\f117"; }
.flaticon-tool-1:before { content: "\f118"; }
.flaticon-video:before { content: "\f119"; }